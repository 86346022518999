<template>
  <GlPageWrap>
    <div class="profile-wrapper">
      <div class="profile">
        <div class="profile-head-text-wrapper">
          <div class="profile-head-text bold-600 fs-36 m-fs-20">
            Profile Information
          </div>
        </div>
        <div class="profile-main-content-wrapper">
          <div class="profile-main-content">
            <ProfileInfoBlock
              align-first
              class="mb-50 m-mb-4"
            >
              <template #first>
                <div class="bold-600">
                  Balance
                </div>
              </template>
              <template #second>
                <div
                  class="flex space-between align-center gap-4 bold fullwidth"
                >
                  <div>
                    <span class="bluet-text fs-36 mr-2 bold-700 ff-exo-2">{{
                      userData.balance || 0
                    }}</span>
                    <gl-icon
                      :height="isMobile ? 18 : 24"
                      name="lookups-blue"
                      :width="isMobile ? 18 : 24"
                    />
                  </div>
                  <div>
                    <Button
                      class="profile-top-up-button bold-600 fs-18"
                      label="Top Up"
                      @click="$router.push({ name: 'payment' })"
                    />
                  </div>
                </div>
              </template>
            </ProfileInfoBlock>
            <hr class="grey-hr mb-50 m-mb-4">
            <ProfileInfoBlock
              align-first
              class="mb-50 bold-600 m-mb-4"
            >
              <template #first>
                <div> Email address </div>
              </template>
              <template #second>
                <div class="flex space-between align-center fullwidth">
                  <div
                    class="profile-email-text grey-transparent-text max-w-450 ellipsis pr-2"
                  >
                    {{ userData.email }}
                  </div>
                  <div>
                    <gl-icon
                      class="pointer ml-3"
                      :height="24"
                      name="edit"
                      :width="24"
                      @click="editEmailModal = true"
                    />
                  </div>
                </div>
              </template>
            </ProfileInfoBlock>

            <hr class="grey-hr mb-50 m-mb-4">

            <div class="bold-600 mb-4">
              Change Password
            </div>
            <gl-form
              ref="passwordForm"
              class="profile-form-wrapper"
              full-submit
              ok-only
              :side-valid="isPasswordFormValid"
              submit-title="Change Password"
              @submit="handleSubmit"
            >
              <ProfileInfoBlock
                align-first
                class="mb-4 bold"
              >
                <template #first>
                  <div> Old Password </div>
                </template>
                <template #second>
                  <gl-input
                    v-model.trim="password"
                    autocomplete="current-password"
                    class="profile__input fullwidth"
                    :class="{'profile__input--disabled' : userData.passwordSkipped}"
                    :disabled="userData.passwordSkipped"
                    hide-control
                    :label="isMobile ? 'Old Password' : ''"
                    name="Old password"
                    placeholder="At least 14 characters"
                    :rules="!userData.passwordSkipped && 'required'"
                    type="password"
                    vid="password"
                  />
                </template>
              </ProfileInfoBlock>
              <ProfileInfoBlock
                align-first
                class="mb-4 bold"
              >
                <template #first>
                  <div> New Password </div>
                </template>
                <template #second>
                  <gl-input
                    v-model.trim="newPassword"
                    autocomplete="current-password"
                    class="profile__input fullwidth"
                    hide-control
                    :label="isMobile ? 'New Password' : ''"
                    name="Password"
                    placeholder="At least 14 characters"
                    rules="required"
                    type="password"
                    vid="newPassword"
                  />
                </template>
              </ProfileInfoBlock>
              <ProfileInfoBlock align-first>
                <template #second>
                  <ValidateBlockWrapper
                    class="max-w-330"
                    :password="newPassword"
                  />
                </template>
              </ProfileInfoBlock>
              <ProfileInfoBlock
                align-first
                class="mb-4 bold"
              >
                <template #first>
                  <div> Confirm New Password </div>
                </template>
                <template #second>
                  <gl-input
                    v-model.trim="sameNewPassword"
                    autocomplete="current-password"
                    class="profile__input fullwidth"
                    hide-control
                    :label="isMobile ? 'Confirm New Password' : ''"
                    name="Password confirmation"
                    placeholder="At least 14 characters"
                    rules="required|confirmed:newPassword"
                    type="password"
                    vid="sameNewPassword"
                  />
                </template>
              </ProfileInfoBlock>
            </gl-form>
          </div>
        </div>
      </div>
    </div>
    <AllDoneModal
      v-model="allDoneModal"
      :message="doneMessage"
      @confirm="allDoneModalConfirm"
    />
    <EditEmailModal
      v-model="editEmailModal"
      @close="editEmailModal = false"
      @submit="editEmailModal = false"
    />
  </GlPageWrap>
</template>

<script>
// Vuex
import { mapState, mapActions } from 'vuex'
// Components
import GlPageWrap from '@/components/layout/gl-page-wrap'
import EditEmailModal from './modals/editEmailModal'
import AllDoneModal from './modals/allDoneModal'
import GlForm from '@/components/gl-form'
import GlInput from '@/components/gl-input'
import GlIcon from '@/components/gl-icon'
import ProfileInfoBlock from '@/pages/profile/components/ProfileInfoBlock'
import Button from 'primevue/button'
import ValidateBlockWrapper from '@/pages/register/components/ValidateBlockWrapper'
// Mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'
// Utils
import { nameHere, hasNumber, checkSpecSymbol } from '@/utils/checks'

export default {
  components: {
    ProfileInfoBlock,
    GlPageWrap,
    EditEmailModal,
    AllDoneModal,
    GlForm,
    GlInput,
    GlIcon,
    Button,
    ValidateBlockWrapper,
  },
  mixins: [deviceWidthMixin],
  data() {
    return {
      editEmailModal: false,
      allDoneModal: false,
      doneMessage: '',
      password: '',
      newPassword: '',
      sameNewPassword: '',
      verificationToken: '',
    }
  },
  computed: {
    ...mapState('user', ['userData']),
    isPasswordFormValid() {
      return (
        !this.checkSpecSymbol(this.newPassword) ||
        !this.hasNumber(this.newPassword) ||
        !this.nameHere(this.newPassword) ||
        this.newPassword.length < 14 ||
        this.newPassword !== this.sameNewPassword || !this.userData.passwordSkipped && !this.password
      )
    },
  },
  created() {
    this.verificationToken = this.$route.query.verificationToken || ''

    if (this.verificationToken) {
      this.verifyEmail({
        token: this.verificationToken,
      })
        .then(({ success, data }) => {
          if (success) {
            this.verificationToken = ''
            this.doneMessage =
              'Your email address has been successfully changed.'
            this.allDoneModal = true
          } else {
            this.$toast.add({
              severity: 'error',
              summary: 'Error Message',
              detail: data,
              life: 3000,
            })
          }
        })
    }
  },

  methods: {
    ...mapActions('user', ['getMe', 'verifyEmail', 'changePassword']),
    nameHere,
    hasNumber,
    checkSpecSymbol,
    async handleSubmit() {
      await this.changePassword({
        password: this.password,
        newPassword: this.newPassword,
      })
        .then(({ success, data }) => {
          if (success) {
            this.doneMessage = 'Your password has been successfully changed.'
            this.allDoneModal = true
            this.password = ''
            this.newPassword = ''
            this.sameNewPassword = ''
            this.$nextTick(() => this.$refs.passwordForm.$refs.form.reset())
            this.getMe()
          } else {
            this.$toast.add({
              severity: 'error',
              summary: 'Error Message',
              detail: data,
              life: 10000,
            })
          }
        })
    },
    allDoneModalConfirm() {
      this.$router.replace({ query: null })
      this.allDoneModal = false
    },
  },
}
</script>

<style>
:root {
  --profile-header-height: 120px;
}
.profile-wrapper {
  display: flex;
  justify-content: center;
  background-color: var(--light-grey-f-8);
  padding-top: var(--header-height);
  font-family: 'Montserrat', sans-serif;
}

.profile input:-webkit-autofill,
.profile input:-webkit-autofill:hover,
.profile input:-webkit-autofill:focus,
.profile input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px var(--light-grey-f-8) inset;
}

.profile input {
  border-radius: 8px;
  background: var(--light-grey-f-8);
}

.profile input,
.profile .gl-input__input--password-icon {
  height: 48px !important;
}

.profile {
  width: 100%;
  min-height: var(--main-content-height);
}

.profile-head-text-wrapper {
  display: flex;
  justify-content: center;
}

.profile-head-text {
  width: var(--main-content-width);
  max-width: var(--main-content-max-width);
  padding-top: 24px;
  padding-bottom: 24px;
  height: var(--profile-header-height);
}

.profile-main-content-wrapper {
  display: flex;
  justify-content: center;
  background: var(--white);
  box-shadow: 0px 0px 100px 0px var(--dark-transparent);
  min-height: calc(var(--main-content-height) - var(--profile-header-height));
  padding-top: 36px;
  padding-bottom: calc(36px + var(--report-banner-height));
}

.profile-main-content {
  width: var(--main-content-width);
  max-width: var(--main-content-max-width);
}

.profile-top-up-button.p-button {
  padding-left: 46px;
  padding-right: 46px;
  height: 48px;
}

.profile-form-wrapper .gl-form__actions {
  margin-left: 230px;
  max-width: 330px;
}

.profile__input--disabled {
  opacity: 0.5;
  pointer-events: none;
}
@media (max-width: 1023px) {
  .profile-main-content-wrapper {
    padding-bottom: calc(80px + var(--report-banner-height));
  }
}

@media (max-width: 767px) {
  .profile {
    display: flex;
    align-items: center;
    flex-direction: column;
    background: var(--grey-white);
    padding-top: 16px;
  }
  .profile-wrapper {
    background: var(--grey-white);
  }
  .profile-head-text-wrapper {
    width: 100%;
  }
  .profile-head-text {
    height: auto;
  }

  .profile-head-text {
    background: var(--white);
    border-radius: 8px 8px 0 0;
    border: 1px solid var(--light-grey-e-f);
    border-bottom: 0;
    padding: 16px;
  }

  .profile-main-content-wrapper {
    width: var(--main-content-width);
    padding-top: 24px;
    background: var(--white);
    padding-bottom: 24px;
    margin-bottom: calc(60px + var(--report-banner-height));
    border-radius: 0 0 8px 8px;
    border: 1px solid var(--light-grey-e-f);
  }

  .profile-form-wrapper .gl-form__actions {
    margin-left: 0;
    max-width: 100%;
  }

  .profile-top-up-button.p-button {
    padding-left: 26px;
    padding-right: 26px;
    height: 40px;
  }
  .profile-email-text {
    max-width: 80%;
  }
}
</style>
