<template>
  <gl-modal
    v-bind="$attrs"
    capitalize-title
    icon="success"
    :icon-height="32"
    :icon-width="32"
    :loading="loading"
    ok-only
    submit-title="Got It"
    title="All done!"
    width="480"
    v-on="$listeners"
    @submit="confirm"
  >
    <template #mainSlot>
      <div class="all-done-modal-message-wrapper break-word">
        {{ message }}
      </div>
    </template>
  </gl-modal>
</template>

<script>
import GlModal from '@/components/gl-modal'

export default {
  components: {
    GlModal,
  },
  inheritAttrs: false,
  props: {
    message: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    confirm() {
      this.$emit('confirm')
    },
  },
}
</script>

<style>
.all-done-modal-message-wrapper {
  padding: 24px 13px 24px 24px;
  font-weight: 500;
}
</style>
