<template>
  <div class="profile-main-content-block-wrapper">
    <div
      class="profile-main-content-block-first"
      :class="[{ 'align-center': alignFirst }]"
    >
      <slot name="first" />
    </div>
    <div class="profile-main-content-block-second">
      <slot name="second" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    alignFirst: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style>
.profile-main-content-block-wrapper {
  display: flex;
}
.profile-main-content-block-first {
  min-width: 230px;
  display: flex;
}
.profile-main-content-block-second {
  min-width: 330px;
  display: flex;
}
@media (max-width: 767px) {
  .profile-main-content-block-first {
    display: none;
  }
  .profile-main-content-block-second {
    min-width: 100%;
    display: flex;
  }
}
</style>
