<template>
  <gl-modal
    v-bind="$attrs"
    capitalize-title
    class="profile-edit-email-modal"
    info-only
    :loading="loading"
    title="Edit email address"
    width="450"
    v-on="$listeners"
    @close="$emit('close')"
  >
    <template slot="content">
      <div class="mb-4 bold">
        Are you sure you want to change email address? <br>
        You wil need to verify new email address.
      </div>
      <gl-form
        ref="editEmailForm"
        class="mb-2"
        full-buttons
        max-height-form="30dvh"
        overflowing-form
        submit-title="Continue"
        @cancel="$emit('close')"
        @submit="onSubmit"
      >
        <gl-input
          v-model="formData.newEmail"
          class="login__input"
          :height="40"
          label="New email addrees"
          name="Email"
          rules="required|email"
          vid="new_email"
        />
        <gl-input
          v-model="formData.password"
          autocomplete="new-password"
          class="login__input"
          hide-control
          label="Password"
          name="Password"
          placeholder="Enter your current password..."
          rules="required"
          type="password"
          vid="password_email"
        />
      </gl-form>
    </template>
  </gl-modal>
</template>

<script>
// Components
import GlModal from '@/components/gl-modal'
import GlInput from '@/components/gl-input'
import GlForm from '@/components/gl-form'
import { mapActions } from 'vuex'

export default {
  components: {
    GlModal,
    GlInput,
    GlForm,
  },
  inheritAttrs: false,
  data() {
    return {
      loading: false,
      formData: {
        newEmail: '',
        password: '',
      },
    }
  },
  methods: {
    ...mapActions('user', ['changeEmail', 'getMe']),
    async onSubmit() {
      if (this.formData.newEmail) {
        this.formData.newEmail = this.formData.newEmail.toLowerCase()
        this.loading = true
        this.changeEmail(this.formData)
          .then(({ success, data }) => {
            this.getMe()
            if (success) {
              this.$toast.add({
                severity: 'success',
                summary: 'Success Message',
                detail: data,
                life: 10000,
              })
              this.$emit('submit')
              this.getMe()
            } else {
              this.$toast.add({
                severity: 'error',
                summary: 'Error Message',
                detail: data,
                life: 3000,
              })
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
  },
}
</script>
<style>
.profile-edit-email-modal .gl-modal .gl-form__actions {
  margin: 0;
}
@media screen and (max-height: 767px) {
  .profile-edit-email-modal .gl-modal .gl-form__actions {
    margin-top: 8px;
  }
}
</style>
